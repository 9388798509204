import React from 'react';
import MoreAboutUs from 'src/components/functional/more-about-us-section';
import Page from 'src/components/functional/page';
import HeroImage from 'images/circles.jpg';
import Strip from 'src/components/functional/strip';
import FeaturedHeroSection
  from 'src/components/functional/hero-section-featured';
import {H3, H5} from 'src/components/functional/headings';

import {useLocation} from '@reach/router';
import {usePageContent} from 'src/functionality/content-provider';
import {Row, Col, Container} from 'react-bootstrap';

const EmployeeSatisfactionSurvey = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);
  return (
    <Page white={true}>
      <FeaturedHeroSection
        bgImage={HeroImage}
        breadcrumbs={true}
        content={content}
        tag="ESS01"
        display={5}
      />
      <Strip padding="4vh">
        The survey covered the following themes:
        <Row>
          <Col sm={6} xs={12}>
            <ul style={{listStyleType: 'disc'}}>
              <li>Overall Employee Sentiment</li>
              <li>Role Clarity</li>
              <li>Learning &amp; Development</li>
              <li>Systems &amp; Processes</li>
              <li>Work Engagement</li>
              <li>Autonomy &amp; Empowerment</li>
              <li>Communication</li>
              <li>Work Environment (including Safety)</li>
            </ul>
          </Col>
          <Col sm={6} xs={12}>
            <ul style={{listStyleType: 'disc'}}>
              <li>Reward &amp; Recognition</li>
              <li>Leadership</li>
              <li>Team Environment</li>
              <li>Customer Service</li>
              <li>Loyalty &amp; Commitment</li>
              <li>Work life Balance</li>
              <li>Ethics &amp; Diversity</li>
              <li>Vision &amp; Values</li>
              <li>Employee Advocacy</li>
            </ul>
          </Col>
        </Row>
        <hr />
        From the 79 invitations sent, 47 responses were received. 19 invitations
        were “unopened”. Program response rates as a percentage of invitations
        sent to that program are:
        <br />
        <br />
        <blockquote>
          57% Client Services Victoria<br />
          80% Client Services NSW<br />
          67% Neuropsychology Assessment &amp; Intervention Service (NPAIS)
          <br />
          22% Business arm<br />
        </blockquote>
        Participation rates can provide insights into employee engagement, with
        lower response rates potentially indicating lower overall engagement.
        <br />
        <br />
        <br />
        <H3>
          Survey Results
        </H3>
        <br />
        <Row>
          <Col lg={6} xs={12}>
            <H5 noUnderline>
              Positive Insights
            </H5>
            <br />
            The results overall, provided some positive insights into the life
            of arbias. Overall, over 80% of employees:
            <ul style={{listStyleType: 'disc'}}>
              <li>
                Are satisfied in their current role and have no plans to leave
                arbias.
              </li>
              <li>
                Have a strong sense of loyalty and commitment and are proud to
                work for arbias
              </li>
              <li>
                Have role clarity and know who their Leader is.
              </li>
              <li>
                Believe there is a strong team environment of trust and respect.
              </li>
              <li>
                Demonstrate a high level of resilience and perseverance.
              </li>
              <li>
                Are satisfied with the workplace culture and have a high level
                of job security.
              </li>
              <li>
                Are optimistic positive changes are happening because of new
                leadership.
              </li>
              <li>
                Feel their work is meaningful and purposeful which provides a
                high level of intrinsic motivation.
              </li>
            </ul>
          </Col>
          <Col lg={6} xs={12}>
            <H5 noUnderline>
              Constructive Insights
            </H5>
            <br />
            The results also demonstrated the existence of the following issues:
            <ul style={{listStyleType: 'disc'}}>
              <li>
                Centralised decision making and lack of consultation.
              </li>
              <li>
                Disrespectful behaviour and poor role modelling from senior
                leaders.
              </li>
              <li>
                Lack of staff resources and learning &amp; development
                opportunities.
              </li>
              <li>
                Breaches in confidentiality and a perception that decisions are
                based on hearsay rather than fact.
              </li>
              <li>
                Lack of appropriate and consistently applied policies and
                procedures.
              </li>
              <li>
                Issues with the application of employee entitlements.
              </li>
              <li>
                Distrust of the intent of the Survey
              </li>
            </ul>
          </Col>
        </Row>
        <br />
        <blockquote>
          <H3>Where to from here</H3>
          <br />
          The arbias executive are progressively implementing the recommendations
          for improvement where appropriate much of which centres around improved
          communication. This work is well underway.
        </blockquote>
      </Strip>
      <MoreAboutUs />
    </Page>
  );
};

export default EmployeeSatisfactionSurvey;

